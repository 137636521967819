import { Category } from './category.model';

export class Gallery {
    public src?:string;
    public thumb:string;
    public srcs?:Array<string>
    public id:number;
    public caption: string;
    public similar?:Array<number>;
    public category:Category;
    public date:string;
    
    static fromJson(obj: any) {
        const newGallery = new Gallery();
        if (obj.src) { newGallery.src = obj.src }
        newGallery.thumb = obj.thumb;
        if (obj.srcs) { newGallery.srcs = obj.srcs.split(",") }
        newGallery.id = Number(obj.id);
        newGallery.caption = obj.caption;
        if (obj.similar) { newGallery.similar = obj.similar.split(",").map(sim => Number(sim)) }
        newGallery.category = obj.category;
        newGallery.date = obj.date;
        return newGallery;
    }
};