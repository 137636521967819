
import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'transl',
    templateUrl: 'transl.component.html'
})
export class TranslComponent {
    
    constructor(@Inject(TranslateService) private translate: TranslateService) {
    }
    
    toggle () {
        if(this.translate.currentLang === "en") {   
            this.translate.use("de");
        }
        else {
            this.translate.use("en");
        }
    }
}