
import { Component, Input } from '@angular/core';
import { Gallery } from '../../model/gallery.model';
import { Category } from '../../model/category.model';


@Component({
    selector: 'gallery-frame',
    templateUrl: 'galleryframe.component.html'
})
export class GalleryFrameComponent {
    @Input() item:Gallery;
    @Input() thumb:Boolean = false;
    src:string;
    link:Array<any> = null;

    constructor() {}

    ngOnInit() {
        if(this.thumb == true || this.item.category == Category.film) {
            this.src = this.item.thumb;
        }
        else if(this.item.src == undefined){
            this.src = this.item.srcs[0];
        }
        else {
            this.src = this.item.src;
        }
        
    }


    
}