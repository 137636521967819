import { Component, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Gallery } from '../../model/gallery.model';
import { Category } from '../../model/category.model';

@Component({
    selector: 'gallery-view',
    templateUrl: 'gallery.component.html'
})
export class GalleryComponent {
    items:Array<Gallery> = [];
    itemsLeft:Array<Gallery> = [];
    itemsRight:Array<Gallery> = [];
    sort = "date";

    filter = {
        photo: true,
        design: true,
        film: true
    };

    constructor(@Inject(DataService) private dataService:DataService) {
        this.showGallery();
    }

    showGallery() {
        this.items = [], this.itemsLeft = [], this.itemsRight = [];
        this.dataService.getGallery().subscribe((data:Array<Gallery>) => {
            for(let item of data) {
                if( (this.filter.design && item.category == Category.design) ||
                    (this.filter.photo && item.category == Category.photography) ||
                    (this.filter.film && item.category == Category.film)) {
                    this.items.push(item);
                    
                }
            }
            if(this.sort == "date") {
                this.items = this.items.sort( (a,b) => a.caption > b.caption ? 1 : -1);
                this.items = this.items.sort( (a,b) => a.date < b.date ? 1 : -1);
            }
            else {
                this.items = this.items.sort( (a,b) => a.date < b.date ? 1 : -1);
                this.items = this.items.sort( (a,b) => a.caption > b.caption ? 1 : -1);
            }
            for(let index = 0; index < this.items.length; index++) {
                if(index % 2 == 0) {
                    this.itemsLeft.push(this.items[index]);
                }
                else {
                    this.itemsRight.push(this.items[index]);
                }
            }
        });
    }
}