import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DataService } from '../../services/data.service';
import { SafeHtml } from '@angular/platform-browser';
import { Project } from '../../model/project.model';
import { Category } from '../../model/category.model';
import { Lang } from '../../model/lang.model';



@Component({
    selector: 'projects-item-view',
    templateUrl: 'projectsitem.component.html'
})
export class ProjectsItemComponent {

    isLoading = true;

    constructor(private route: ActivatedRoute, @Inject(DataService) private dataService:DataService) {
        this.loadProject(Number(this.route.snapshot.paramMap.get("id")));
        
    }
    article:Project;

    articleHTML:SafeHtml = "Loading..."

    loadProject(id:number) {
        this.dataService.getProjects().subscribe((data:Array<Project>) => {
            console.log(data, id);
            for(let article of data) {
                if(article.id == id) {
                    this.article = article;
                    this.isLoading = false;
                    break;
                }
            }
        });

    }


}