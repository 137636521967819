import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { NotFound404Component } from './pages/404/404.component';
import { AboutComponent } from './pages/about/about.component';
import { ProjectsItemComponent } from './pages/projectsitem/projecstitem.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { GalleryItemComponent } from './pages/galleryitem/galleryitem.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'projects', component: ProjectsComponent},
  {path: 'projects/:id', component: ProjectsItemComponent},
  {path: 'about', component: AboutComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'gallery/:id', component: GalleryItemComponent},
  {path: '**', component: NotFound404Component},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
