import { Category } from './category.model';
import { Lang } from './lang.model';

export class Project {
    public title:string;
    public id:number;
    public description:string;
    public article:string;
    public lang:Lang;
    public datesort: string;
    public category:Category;

    static fromJson(project: any): Project {
        const newProject = new Project();
        newProject.title = project.title;
        newProject.id = project.id;
        newProject.description = project.description;
        newProject.article = project.article;
        newProject.lang = project.lang;
        newProject.datesort = project.datesort;
        newProject.category = project.category;
        return newProject;
    }
}