import { Component, Input } from "@angular/core";

@Component({
    selector: 'featured-frame',
    templateUrl: 'featuredframe.component.html',
    styleUrls: ['featuredframe.component.css']
})
export class FeaturedframeComponent {
    @Input() title:String;
    @Input() img:String;
}