import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { Project } from '../model/project.model';
import { Gallery } from '../model/gallery.model';

@Injectable({ providedIn: 'root' })
export class DataService {
    constructor(private http: HttpClient) { }

    baseURL = 'assets/data/';

    getProjects(): Observable<Project[]> {
        return new Observable<Project[]>(subscriber => {
            this.http.get("https://cloud.patrickguenthard.ch/cockpit/api/collections/get/Project").subscribe((result: any) => {
                subscriber.next(result.entries.map(entry => {
                    return Project.fromJson(entry);
                }));
            })
        });
        // this.http.get(this.baseURL + "projects.json");
    }

    getGallery(): Observable<Gallery[]> {
        return new Observable<Gallery[]>(subscriber => {
            this.http.get("https://cloud.patrickguenthard.ch/cockpit/api/collections/get/Gallery").subscribe((result: any) => {
                subscriber.next(result.entries.map(entry => {
                    return Gallery.fromJson(entry);
                }));
            })
        });
        //return this.http.get(this.baseURL + "gallery.json");
    }

    getArticle(filename: string) {
        return this.http.get(this.baseURL + "articles/" + filename);
    }

    getFeatured() {
        return this.http.get(this.baseURL + "featured.json");
    }
}