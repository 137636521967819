import { Component, Inject } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'root-view',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    
    constructor(@Inject(TranslateService) private translate: TranslateService) {
        translate.setDefaultLang('de');
        translate.use('de');
    }

    name = 'Angular';
}
