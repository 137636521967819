import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Featured } from 'src/app/model/featured.model';


@Component({
    selector: 'home-view',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.css']
})
export class HomeComponent implements OnInit{
    name = 'Angular';
    featuredList:Array<Featured> = [];

    constructor(private dataService:DataService) {}

    ngOnInit() {
        this.dataService.getFeatured().subscribe((data:Array<Featured>) => {
            this.featuredList = data;
        });
    }
}

/*
var root = null;
var useHash = true; // Defaults to: false
var hash = '#!'; // Defaults to: '#'
var router = new Navigo(root, useHash, hash);

let lang = "de";

function translate() {
    for(let elm of document.querySelectorAll("[data-transl]")) {
        elm.innerHTML = translation[lang][elm.getAttribute("data-transl")]
    }
}

translate();

function commonDateFormat(dateStr) {
    let months = {
        "en" : ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct",
            "Nov","Dec"
        ],
        "de" : ["Jan","Feb","Mär","Apr","Mai","Jun","Jul","Aug","Sep","Okt",
            "Nov","Dez"
        ]
        
        
    };
    let date = new Date(dateStr);
    return months[lang][date.getMonth()] + " " + date.getFullYear();
}

function showPage(id) {
    for(let page of document.querySelectorAll(".page")) {
        if(page.id != id) {
            page.style.display = "none";
        }
        else {
            page.style.display = "block";
        }
    }
}

function projectsItemTemplate(prjEntry) {
    return ""
}

function galleryFrameTemplate(item) {
    let src;
    if(item.type == "film") {
        src= item.thumb;
    }
    else {
        src = item.src == undefined ? item.srcs[0] : item.src;
    }
    return "<a onclick=\"router.navigate('/galleryitem/"+item.id+"')\" class=\"gallery-frame ptr\"><img class=\"gallery-image\" src=\"" + src + "\" />"+item.caption+"<br/>" +commonDateFormat(item.date)+"</a>";
}
    
function renderProjectList() {
    let listHTML = "", counter=0;
    let projectList;
    if(document.querySelector("[name=\"sort\"][value=\"date\"]").checked) {
        projectList = projects.sort( (a,b) => a.datesort < b.datesort ? 1 : -1);
    }
    else {
        projectList = projects.sort( (a,b) => a.title > b.title ? 1 : -1);
    }
    for(let prjEntryIndex in projectList) {
        let prjEntry = projects[prjEntryIndex];
        if( (prjEntry.lang == lang || document.querySelector(".projects-filter-check[name=\"lang\"]").checked ) && document.querySelector(".projects-filter-check[name=\""+prjEntry.category+"\"]").checked) {
            listHTML += projectsItemTemplate(prjEntry);
            counter++;
        }
    }
    if(counter == 0) {
        listHTML = "<p data-transl=\"select-category\"></p>";
        
    }
    document.getElementById("projects-list").innerHTML = listHTML;
    translate();
}

function renderGallery() {
    let galleryRow1 = document.querySelector("#gallery-row-1");
    let galleryRow2 = document.querySelector("#gallery-row-2");
    let galleryRow1HTML = "";
    let galleryRow2HTML = "";
    
    let galleryList;
    if(document.querySelector("[name=\"sortg\"][value=\"date\"]").checked) {
        galleryList = gallery.sort( (a,b) => a.caption > b.caption ? 1 : -1);
        galleryList = gallery.sort( (a,b) => a.date < b.date ? 1 : -1);
    }
    else {
        galleryList = gallery.sort( (a,b) => a.date < b.date ? 1 : -1);
        galleryList = gallery.sort( (a,b) => a.caption > b.caption ? 1 : -1);
    }
    
    let index = 0;
    
    for(let item of galleryList) {
        if(document.querySelector(".gallery-filter-check[name=\""+item.type+"\"]").checked) {
            let newHTML;
            newHTML = galleryFrameTemplate(item);
            if(index % 2 == 0) {
                galleryRow1HTML += newHTML;
            }
            else {
                galleryRow2HTML += newHTML;
            }
            index++;
            
        }
    }
    galleryRow1.innerHTML = galleryRow1HTML;
    galleryRow2.innerHTML = galleryRow2HTML;
}

function renderGalleryItem(id) {
    let item;
    for(let gitem of gallery) {
        if(gitem.id == id) {
            item = gitem;
        }
    }
    let itemsHTML = "";
    for(let src of item.src == undefined ? item.srcs : [item.src]) {
        if(item.type == "film") {
            itemsHTML += "<div class=\"gallery-frame\"><video controls class=\"gallery-image\"><source src=\""+src+"\"/></video></div>";
        }
        else {
            itemsHTML += "<div class=\"gallery-frame\"><img src=\""+src+"\" class=\"gallery-image\" /></div>";
        }
    }
    if(item.similar != undefined) {
        
        itemsHTML += "<h4 class=\"similar\" data-transl=\"similar\"></h4><div class=\"related-items-view\">";
        for(let similarId of item.similar) {
            let similarItem;
            for(let gitem of gallery) {
                if(gitem.id == similarId) {
                    similarItem = gitem;
                }
            }
            itemsHTML += galleryFrameTemplate(similarItem);
        }
        
        itemsHTML += "</div>";
    }
    document.querySelector("#gallery-item-display").innerHTML = itemsHTML;
    document.querySelector("#gallery-item-title").innerHTML = item.caption;
    document.querySelector("#gallery-item-date").innerHTML = commonDateFormat(item.date);
    translate();
}

function renderProject(id) {
    for(let project of projects) {
        if(project.id == id) {
            document.getElementById("project-title").innerHTML = project.title;
            document.getElementById("project-article").innerHTML = project.article;
        }
    }
}

for(let check of document.querySelectorAll(".projects-filter-check, [name=\"sort\"]")) {
    check.addEventListener("change", () => {renderProjectList()});
}

for(let check of document.querySelectorAll(".gallery-filter-check, [name=\"sortg\"]")) {
    check.addEventListener("change", () => {renderGallery()});
}


for(let check of document.querySelectorAll(".lang-select")) {
    check.addEventListener("click", () => {lang = lang == "de" ? "en" : "de"; translate(); renderProjectList()});
}

router.on(
    {
        "/" : function(params) {
            showPage("home");
        },
        "/projects/" : function(params) {
            renderProjectList();
            showPage("projects");
        },
        "/gallery" : function(params) {
            renderGallery();
            showPage("gallery");
        },
        "/galleryitem/:id" : function(params) {
            renderGalleryItem(params.id);
            showPage("gallery-item");
        },
        "/project/:id" : function(params) {
            renderProject(params.id);
            showPage("project");
        },
        "/about" : function(params) {
            showPage("about");
        }
    
    }
).resolve();*/