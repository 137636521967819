import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { NotFound404Component } from './pages/404/404.component';
import { AboutComponent } from './pages/about/about.component';
import { ProjectsItemComponent } from './pages/projectsitem/projecstitem.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { GalleryItemComponent} from './pages/galleryitem/galleryitem.component'
import { GalleryFrameComponent } from './components/galleryframe/galleryframe.component';

import { TranslComponent } from './components/transl/transl.component';
import { DateComponent } from './components/date/date.component';
import { FooterComponent } from './components/footer/footer.component';


import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { NgxMdModule } from 'ngx-md';
import { FeaturedframeComponent } from './components/featuredframe/featuredframe.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectsComponent,
    NotFound404Component,
    AboutComponent,
    ProjectsItemComponent,
    TranslComponent,
    GalleryComponent,
    DateComponent,
    GalleryFrameComponent,
    GalleryItemComponent,
    FooterComponent,
    FeaturedframeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxMdModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}