
import { Component, Input, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'date',
    templateUrl: 'date.component.html'
})
export class DateComponent {
    @Input() datestr:string;
    month
    year

    constructor(@Inject(TranslateService) private translate:TranslateService) {        
    }

    ngOnInit() {
        let dateObj = new Date(this.datestr);
        this.year = dateObj.getFullYear();
        this.month = this.translate.translations[this.translate.currentLang].months[dateObj.getMonth()];

    }
    
}