import { Component, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
    selector: 'projects-view',
    templateUrl: 'projects.component.html'
})
export class ProjectsComponent {
    filter = {
        grage:true,
        photo:true,
        film:true,
        progra:true,
        allang:false
    };

    sort:String = "date";

    constructor(@Inject(DataService) private dataService:DataService, 
                @Inject(TranslateService) private translate: TranslateService) {
        translate.onLangChange.subscribe(() => this.showProjects());
    }

    ngOnInit() {
        this.showProjects();
    }

    projects:Array<any>;

    showProjects () {
        this.projects = []
        this.dataService.getProjects().subscribe((data:Array<any>) => {
            console.log(data);
            for(let project of data) {
                if( (project.lang == this.translate.currentLang || this.filter.allang) && ((this.filter.grage && project.category == "design") ||
                    (this.filter.photo && project.category == "photo") ||
                    (this.filter.film && project.category == "film") ||
                    (this.filter.progra && project.category == "programming"))) {
                    this.projects.push(project);
                }
            }
            if(this.sort == "date") {
                this.projects = this.projects.sort( (a,b) => a.datesort < b.datesort ? 1 : -1);
            }
            else {
                this.projects = this.projects.sort( (a,b) => a.title > b.title ? 1 : -1);
            }

        })
    }

    
}
