import { Component, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Gallery } from '../../model/gallery.model';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'gallery-item-view',
    templateUrl: 'galleryitem.component.html'
})
export class GalleryItemComponent {
    isLoading = true;
    
    item:Gallery = null;
    type:string = "";
    similar:Array<Gallery> = [];
    

    srcs:Array<string> = [];

    constructor(private route: ActivatedRoute, @Inject(DataService) private dataService:DataService) {
        route.params.subscribe(val => {
            this.loadGalleryItem(Number(this.route.snapshot.paramMap.get("id")));   
        });
             
    }

    loadGalleryItem(id:number) {
        this.srcs = [];
        this.similar = [];
        this.dataService.getGallery().subscribe((data:Array<Gallery>) => {
            for(let item of data) {
                if(item.id == id) {
                    
                    console.log(item.id, id);
                    this.item = item;
                    if(this.item.src == undefined) {
                        for(let src of this.item.srcs) {
                            this.srcs.push(src);
                        }
                    }
                    else {
                        this.srcs.push(this.item.src)
                    }
                    if(this.item.similar != undefined) {
                        for(let simItem of data) {
                            for(let simId of this.item.similar) {
                                if(simItem.id == simId) {
                                    console.log(simId);
                                    this.similar.push(simItem);
                                }
                            }
                        }
                    }
                    break;
                }
            }
            this.isLoading = false;
        });
        
    }
}